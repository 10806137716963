<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-grid dashboard" style="position: relative" v-if="!showloaderpage">
                <div class="p-col-12 p-md-12">
                    <div class="card widget-social">
                        <div class="p-d-flex">
                            <span class="subtext p-mt-2 p-text-capitalize">
                                <h6 class="p-mb-2">Client Name : {{ clientsList.maa2 ? clientsList.maa2 : '-' }} {{ clientsList.maa4 ?
                                    `(${clientsList.maa4})` : '' }} </h6>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="editClientInfo()" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-id-card" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Client Info
                        </template>
                    </Card>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="editClientConfig()" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-globe" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Client Config
                        </template>
                    </Card>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="editClientPlan()" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-ticket" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Client Plan
                        </template>
                    </Card>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="editClientAddFund()" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-wallet" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Add Fund
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="clientRole" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-tag" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Client Role
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="clientDocument" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-file" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Client Document
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="serviceSetting" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-cog" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Services Setting
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="addDebit" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-credit-card" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Add Debit
                        </template>
                    </Card>
                </div>

                <!-- <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="WhatsAppSettings" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-whatsapp" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            WhatsApp Settings
                        </template>
                    </Card>
                </div>  -->
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="TemplateActions" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-envelope" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Template Actions
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="WhatsAppTemplateSetting" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-book" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            WhatsApp Template Settings
                        </template>
                    </Card>
                </div>
                <!-- <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="RCSTemplateSetting" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-comment" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            RCS Template Settings
                        </template>
                    </Card>
                </div> -->
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="WebBOTTemplateSetting" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-book" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Web Bot Template Settings
                        </template>
                    </Card>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="WhatsAppNumbers" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-sliders-h" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            WhatsApp Numbers
                        </template>
                    </Card>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="BannerImage" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-images" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Lokneta App Banner Images
                        </template>
                    </Card>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="AppVersion" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-mobile" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            App Version Settings
                        </template>
                    </Card>
                </div>



            </div>
            <div v-if="showloaderpage" style="height: 544px; position: relative">
                <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '../../service/ApiService';
import router from '@/router';
import { useRoute } from 'vue-router';

export default {
    data() {
        return {
            clientsList: [],
            clientname: '',
            showloaderpage: false,
            routeParam: '',
            localClientName: '',
            clientnamevalue: '',
        };
    },

    mounted() {
        const route = useRoute();
        this.routeParam = route.params.clientId;
        this.getclientdetails({ maa1: this.routeParam });
        if (localStorage.localClientName) {
            this.localClientName = localStorage.localClientName;
        }
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    methods: {
        getclientdetails(ev) {
            this.showloaderpage = true;
            this.ApiService.getclientdetails(ev).then((data) => {
                if (data.status == 200) {
                    this.clientsList = data.data.records;
                    this.showloaderpage = false;
                    localStorage.localClientName = this.clientsList.maa2;
                } else {
                    this.clientsList = '';
                    this.showloaderpage = false;
                }

            });
        },
        editClientInfo() {
            router.push({
                name: "clientinfo",
                params: { clientSubId: this.routeParam },

            });
            // // window.open(routeData.href, "_self");
        },
        editClientConfig() {
            router.push({
                name: "clientconfig",
                params: { clientSubId: this.routeParam },

            });
            // // window.open(routeData.href, "_self");
        },
        editClientPlan() {
            // router.push({ path: '/clients/dashboard/clientplan' });
            router.push({
                name: "clientplan",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },

            });
            // // window.open(routeData.href, "_self");
        },
        editClientAddFund() {
            router.push({
                name: "clientaddfund",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },

            });
            // // window.open(routeData.href, "_self");
            // router.push({ path: '/clients/dashboard/addfund' });
        },
        clientRole() {
            router.push({
                name: "client-role",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },

            });
            // // window.open(routeData.href, "_self");
            // router.push({ path: '/clients/dashboard/addfund' });
        },
        clientDocument() {
            router.push({
                name: "client-document",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },

            });
            // // window.open(routeData.href, "_self");
            // router.push({ path: '/clients/dashboard/addfund' });
        },
        serviceSetting() {
            router.push({
                name: "service-setting",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },

            });
            // // window.open(routeData.href, "_self");
            // router.push({ path: '/clients/dashboard/addfund' });
        },
        addDebit() {
            router.push({
                name: "add-debit",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },

            });
            // // window.open(routeData.href, "_self");
            // router.push({ path: '/clients/dashboard/addfund' });
        },
        WhatsAppSettings() {
            router.push({
                name: "whatsapp-settings",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },

            });
            // // window.open(routeData.href, "_self");
            // router.push({ path: '/clients/dashboard/addfund' });
        },
        WhatsAppTemplateSetting() {
            router.push({
                name: "whatsapp-template-settings",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },

            });
        },
        TemplateActions() {
            router.push({
                name: "template-actions",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },

            });
        },
        WebBOTTemplateSetting() {
            router.push({
                name: "web-bot-template-settings-dashboard",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },
            });
        },
        WhatsAppNumbers() {
            router.push({
                name: "whatsapp-numbers",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },
            });
        },
        BannerImage() {
            router.push({
                name: "banner-images",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },
            });
        },
        AppVersion() {
            router.push({
                name: "app-version-setting",
                params: { clientSubId: this.routeParam, clientnamevalue: this.clientsList.maa2 },
            });
        }
    },
};
</script>


<style lang="scss" scoped>
/* Responsive */
.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

.p-button-xs {
    width: 1rem;
}

.p-button.p-button-xs {
    padding: 0.925rem 0.925rem;
}

.p-button.p-button-xs .p-button-icon {
    font-size: 0.675rem;
}

.p-button.p-button-xs.p-button-icon-only.p-button-rounded {
    height: 1rem;
}

::v-deep(.p-progressbar) {
    height: 1rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #689f38;
    }

    .p-progressbar-label {
        display: block;
        color: #ffffff;
        line-height: 13px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-datatable) {
        &.p-datatable-customers {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr {
                border-bottom: 1px solid #dee2e6;

                >td {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 0 none !important;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none;

                    .p-column-title {
                        padding: 0.4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -0.4rem 1rem -0.4rem -0.4rem;
                        font-weight: bold;
                    }

                    .p-progressbar {
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }
}

.dashboard-card:hover {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 10px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    cursor: pointer;
    color: #3F51B5;
}
</style>